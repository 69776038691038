import {
  CSpinner
} from '@coreui/react'



function PageLoader(){

    return (
      <div className="loader-container">
        <CSpinner className="page-loader" color="primary"/>
        <div className="modal-backdrop show remove-index"></div>
      </div>
      )
}  

export default PageLoader  
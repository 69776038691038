import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './scss/style.scss';
import PrivateRoute from './components/PrivateRoute'
import PageLoader from './components/Loader'
import { getToken, getLoggedInUserDetails } from 'src/utils/auth'


// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// )

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

// MaheshB start here

// login
const UserLogin = React.lazy(() => import(`./views/pages/login/Login`));
const CenterLogin = React.lazy(() => import(`./views/center/login/Login`));
const Fingerprint = React.lazy(() => import(`./views/Fingerprint`));
// const UploadReport = React.lazy(() => import(`./views/qr_report_upload/UploadReport`));
const token = getToken()


class App extends Component {

    render() {
        //console.log(process.env.REACT_APP_API_URL);
        return (
            <BrowserRouter>
                <React.Suspense fallback={<PageLoader />}>
                    <Switch>
                        <Route exact path="/fp" name="Login Page" render={props => <Fingerprint {...props} />} />
                        {/* <PrivateRoute path="/center/login" name="Dashboard" component={TheLayout} />
              <Route
                    {...props}
                    render={({ location }) => token == null ? (
                      <Redirect
                        to='/center/login'
                      />
                    ) : (
                      <UserContext.Provider value={loggedInUserDetails}>
                        <Component {...props} />
                      </UserContext.Provider>  
                      )}
                  /> */}
                        <Route exact path="/center/login" name="Login Page" render={props => <CenterLogin {...props} />} />
                        {/* <Route exact path="/qrcode-report-upload" name="Upload Report" component={UploadReport} /> */}
                        <Route exact path="/login" name="Login Page" render={props => <UserLogin {...props} />} />
                        {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}

                        {/* <Route path="/" name="Dashboard" render={props => <TheLayout {...props}/>} /> */}
                        <PrivateRoute path="/" name="Dashboard" component={TheLayout} />
                        {/* <Route component={Page404} /> */}
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
        );
    }
}

export default App;

import CryptoJS from 'crypto-js';

const SALT = process.env.REACT_APP_AES_KEY

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SALT).toString();
}

const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SALT);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export const getToken = () => {
  return localStorage.getItem('token') || null;
}

export const saveToken = (token) => {
  return localStorage.setItem('token', token);
}

export const removeToken = () => {
  localStorage.removeItem("token");
}

export const saveLoggedInUserDetails = (userData) => {
   //localStorage.setItem('user', JSON.stringify(userData));
  localStorage.setItem('user', encryptData(userData));
}

export const removeLoggedInUserDetails = () => {
  localStorage.removeItem('user');
  }

export const getLoggedInUserDetails = () => {
  //return JSON.parse(localStorage.getItem('user'));
  if(localStorage.getItem('user'))
    return decryptData(localStorage.getItem('user'));
  return null;  
}

export const getValidityToken = () => {
  return localStorage.getItem('validity_token') || null;
}

export const saveValidityToken = (validity_token) => {
  return localStorage.setItem('validity_token', validity_token);
}

export const getKioskId = () => {
  return localStorage.getItem('kiosk_id') || 100;
}

export const saveKioskId = (kioskId=100) => {
  localStorage.setItem('kiosk_id', kioskId)
}

export const removeKioskId = () => {
  localStorage.removeItem("kiosk_id");
}